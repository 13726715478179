import { useCallback, useMemo } from 'react';
import { useRouter } from 'adapter/next/router';
import type { FormValues, SanityForm } from '../../../types';
import { useApollo, useUserParameters } from '../../../../../hooks';
import { evaluateFieldEnrich } from '../../../utils';
import { useFormSubmission, useEventId } from '../../../hooks';

type UseFormProps = {
  form: SanityForm;
  initialValues: FormValues | undefined;
  onFormSuccess: (() => void) | undefined;
  onFormError: (() => void) | undefined;
};

export const useFullForm = (props: UseFormProps) => {
  const { form, initialValues, onFormSuccess, onFormError } = props;

  const { fields } = form;

  const { fetchApolloData } = useApollo();
  const { fetchUserParameters } = useUserParameters();
  const eventId = useEventId();
  const { query } = useRouter();

  const formInitialValues = useMemo(
    () =>
      fields!.reduce(
        (acc, { name }) => ({ ...acc, [name]: initialValues ? initialValues[name] || '' : '' }),
        {}
      ),
    [fields, initialValues]
  );

  const { handleSubmit: onSubmit } = useFormSubmission({
    form,
    eventId,
    onFormSuccess,
    onFormError
  });

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      // before submitting the form, we are enriching the hidden and empty fields (optional fields)
      const finalValues = { ...values };
      const emailField = fields.find((field) => field.type === 'email');
      const emailFieldValue = emailField ? finalValues[emailField.name] : '';

      const apolloData =
        emailField && emailFieldValue
          ? await fetchApolloData({ email: emailFieldValue as string })
          : undefined;
      const userParameters = await fetchUserParameters();

      fields
        // we are also enriching the non hidden fields that are empty
        .filter((field) => field.type === 'hidden' || !finalValues[field.name])
        .forEach((field) => {
          const { enrichValue, isEligibleForEnrichment } = evaluateFieldEnrich({
            field,
            apolloData,
            userParameters,
            urlQuery: query,
            eventId
          });

          if (isEligibleForEnrichment) {
            finalValues[field.name] = enrichValue;
          }
        });

      return onSubmit(finalValues);
    },
    [fields, onSubmit, fetchApolloData, fetchUserParameters]
  );

  return {
    eventId,
    formInitialValues,
    handleSubmit
  };
};
