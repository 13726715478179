import type { FormField, FormFieldCondition, FormFieldConditionGroup, FormValues } from '../types';

export const evaluateConditionalLogic = (
  field: FormField,
  formValues: FormValues
): { isFieldVisible: boolean } => {
  const { conditionalLogic } = field;

  if (
    !conditionalLogic ||
    !conditionalLogic.enabled ||
    !conditionalLogic.conditions ||
    !conditionalLogic.action ||
    !conditionalLogic.logicalOperator ||
    conditionalLogic.conditions.length < 1
  ) {
    return { isFieldVisible: true };
  }

  const result = evaluateConditions(
    conditionalLogic.conditions,
    formValues,
    conditionalLogic.logicalOperator
  );

  return { isFieldVisible: conditionalLogic.action === 'show' ? result : !result };
};

const evaluateConditions = (
  conditions: Array<FormFieldCondition | FormFieldConditionGroup>,
  formValues: FormValues,
  logicalOperator: 'AND' | 'OR'
): boolean => {
  return logicalOperator === 'AND'
    ? conditions.every((condition) => evaluateConditionOrGroup(condition, formValues))
    : conditions.some((condition) => evaluateConditionOrGroup(condition, formValues));
};

const evaluateConditionOrGroup = (
  condition: FormFieldCondition | FormFieldConditionGroup,
  formValues: FormValues
): boolean => {
  if (condition._type === 'conditionGroup') {
    return evaluateConditions(
      condition.conditions || [],
      formValues,
      condition.logicalOperator || 'AND'
    );
  } else {
    return evaluateSingleCondition(condition, formValues);
  }
};

const evaluateSingleCondition = (
  condition: FormFieldCondition,
  formValues: FormValues
): boolean => {
  const { conditionField, matchValue, operator } = condition;
  const fieldValue = conditionField ? formValues[conditionField] : undefined;

  switch (operator) {
    case 'equal':
      return fieldValue === matchValue;
    case 'notEqual':
      return fieldValue !== matchValue;
    case 'contain':
      return String(fieldValue).includes(matchValue!);
    case 'notContain':
      return !String(fieldValue).includes(matchValue!);
    case 'startWith':
      return String(fieldValue).startsWith(matchValue!);
    case 'endWith':
      return String(fieldValue).endsWith(matchValue!);
    case 'hasValue':
      return fieldValue !== undefined && fieldValue !== null && fieldValue !== '';
    case 'hasNoValue':
      return fieldValue === undefined || fieldValue === null || fieldValue === '';
    case 'greaterThan':
      return parseInt(fieldValue, 10) > parseInt(matchValue!, 10);
    case 'lessThan':
      return parseInt(fieldValue, 10) < parseInt(matchValue!, 10);
    default:
      return false;
  }
};
