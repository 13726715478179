import React, { useCallback } from 'react';
import { useRouter } from 'adapter/next/router';
import toast from 'react-hot-toast';
import { ToastBody } from '@superside/ui';
import type { FormValues, SanityForm } from '../types';

export const useFormSubmissionSuccess = ({ form }: { form: SanityForm }) => {
  const { formSuccess } = form;
  const { behavior, redirect, toastSuccessMessage } = formSuccess || {};

  const history = useRouter();

  const handleRedirect = useCallback(
    (redirectLink: string) => {
      if (redirectLink.includes('https://www.superside.com')) {
        const path = redirectLink.trim().split('https://www.superside.com');

        if (path.length > 1 && path[1]) {
          history.push(path[1]);
        } else {
          location.assign(redirectLink);
        }
      } else if (!redirectLink.includes('http')) {
        history.push(redirectLink);
      } else {
        location.assign(redirectLink);
      }
    },
    [history]
  );

  const handleFormSuccess = useCallback(
    (formValue: FormValues) => {
      if (behavior === 'redirect' && redirect) {
        if (redirect.queryParams && redirect.queryParams?.length > 0) {
          const queryParams = new URLSearchParams();

          redirect.queryParams.forEach((queryParam) => {
            if (queryParam._type === 'payloadItem') {
              queryParams.append(queryParam.key, queryParam.value);
            }

            if (queryParam._type === 'formFieldPayloadItem' && queryParam.fieldName) {
              const fieldValue = formValue[queryParam.fieldName];
              const queryParamValue = Array.isArray(fieldValue) ? fieldValue.join(',') : fieldValue;

              queryParams.append(queryParam.key || queryParam.fieldName, queryParamValue);
            }
          });

          handleRedirect(`${redirect.link}?${queryParams.toString()}`);

          return;
        }

        handleRedirect(redirect.link);
      }

      if (behavior === 'toast' && toastSuccessMessage) {
        toast.custom(
          (t) => (
            <ToastBody
              toastId={t.id}
              config={{
                text: toastSuccessMessage,
                type: 'success',
                countDownDuration: 10
              }}
            />
          ),
          {
            duration: 10000
          }
        );
      }
    },
    [behavior, redirect, handleRedirect]
  );

  return { handleFormSuccess };
};
