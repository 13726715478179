import React, { type FC } from 'react';
import { ArrowLeft, ArrowRight, Spinner } from 'phosphor-react';
import { Box, Button, cn } from '@superside/ui';

type PrevNextButtonsProps = {
  isLoading: boolean;
  withBack: boolean;
  onNext: () => void;
  onPrev: () => void;
};

export const PrevNextButtons: FC<PrevNextButtonsProps> = (props) => {
  const { onNext, onPrev, withBack, isLoading } = props;

  return (
    <Box className='flex-row items-center gap-4'>
      <Button
        variant='iconGhost'
        size='base'
        type='button'
        onClick={(e) => {
          e.preventDefault();
          onPrev();
        }}
        icon={<ArrowLeft weight='bold' />}
        className={cn('p-4', !withBack && 'invisible')}
        aria-label='Previous'
        disabled={isLoading || !withBack}
      />

      {/* TODO implement loading button on UI */}
      <Button
        variant='outline'
        size='base'
        type='button'
        onClick={(e) => {
          e.preventDefault();
          onNext();
        }}
        className='p-4'
        disabled={isLoading}
        aria-label='Next'
      >
        {isLoading ? <Spinner className='animate-spin' /> : <ArrowRight weight='bold' />}
      </Button>
    </Box>
  );
};
