import React, { useCallback, type FC } from 'react';
import { useField, type FieldInputProps } from 'react-final-form';
import {
  Box,
  cn,
  GridSelect,
  PhoneInput,
  Slider,
  TextInput,
  Heading,
  Text,
  CheckBox,
  Superscript
} from '@superside/ui';
import type { FormField } from '../../../types';
import { validateField } from '../../../utils';
import { FormConsentFieldName } from '../../../constants';

type ProgressiveFormFieldProps = {
  field: FormField;
  hideLabel?: boolean;
  withConsent?: boolean;
  className?: string;
  disabled?: boolean;
};

export const ProgressiveFormField: FC<ProgressiveFormFieldProps> = (props) => {
  const { field, hideLabel = false, className, withConsent, disabled } = props;

  const {
    type,
    name,
    label,
    multiple,
    options,
    placeholder,
    autocomplete,
    sliderSettings,
    gridSelectSettings
  } = field;

  const { input: inputProps, meta } = useField(name, {
    validate: async (value) => (await validateField({ field, value })).message
  });

  const { input: consentInputProps, meta: consentMeta } = useField(FormConsentFieldName, {
    validate: async (value) => {
      if (withConsent && !value) {
        return 'Please agree to the terms to proceed';
      }
    }
  });

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLElement>) => {
      if (!disabled) {
        inputProps.onChange(e);
      }
    },
    [disabled, inputProps.onChange]
  );

  const { error, submitError, touched } = meta;
  const { error: consentError, touched: consentTouched } = consentMeta;

  const hasError = (touched || consentTouched) && (error || submitError || consentError);

  const isTwoPartField = ['grid-select', 'slider'].includes(type);

  const Input: FC<FieldInputProps<any, HTMLElement>> = useCallback((inputProps) => {
    switch (type) {
      case 'email':
      case 'text':
        return (
          <TextInput
            {...inputProps}
            id={name}
            autoFocus
            type={type}
            autoComplete={autocomplete}
            placeholder={placeholder}
            variant='ghost'
            className={cn('placeholder:text-bor-foreground/80 text-2xl lg:text-3xl', className)}
          />
        );
      case 'phone':
        return (
          <PhoneInput
            {...inputProps}
            autoFocus
            autoComplete={autocomplete}
            id={name}
            placeholder={placeholder}
            variant='ghost'
            className={cn('text-2xl lg:text-3xl', className)}
          />
        );
      case 'grid-select':
        return (
          <GridSelect
            {...inputProps}
            {...gridSelectSettings}
            withMultiple={multiple}
            id={name}
            // @ts-expect-error errors on CI
            options={options}
          />
        );
      case 'slider':
        return <Slider {...inputProps} {...sliderSettings} />;
      default:
        return <></>;
    }
  }, []);

  return (
    <Box className='relative w-full items-start gap-2'>
      <div className='absolute -top-14 flex w-full flex-col items-start gap-1 lg:-top-12'>
        {label && !hideLabel && (
          <Superscript size='xs'>
            <label htmlFor={name} className='text-bor-foreground/50'>
              {label}
            </label>
          </Superscript>
        )}

        {hasError ? (
          <span data-testid='field-validation' className='text-coral-500 text-sm font-semibold'>
            {error || submitError || consentError}
          </span>
        ) : null}
      </div>

      {isTwoPartField ? (
        <Heading type='h4' className='text-light lg:text-3xl'>
          {placeholder}
        </Heading>
      ) : (
        <Input {...inputProps} disabled={disabled} onChange={handleOnChange} />
      )}

      <div className='absolute bottom-[-50px] w-[calc(100%+120px)] translate-y-full'>
        {isTwoPartField ? (
          <Input {...inputProps} disabled={disabled} onChange={handleOnChange} />
        ) : null}

        {withConsent ? (
          <div className='flex flex-col gap-2'>
            <Text className='text-bor-primary font-medium' size='sm'>
              Required to proceed
            </Text>

            <CheckBox
              {...consentInputProps}
              label={
                <>
                  By proceeding, you consent to our{' '}
                  <a href='/terms-of-use' target='_blank' className='font-bold underline'>
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a href='/privacy' target='_blank' className='font-bold underline'>
                    Privacy Policy
                  </a>
                  .
                </>
              }
            />
          </div>
        ) : null}
      </div>
    </Box>
  );
};
