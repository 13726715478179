import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'adapter/next/router';
import {
  useApollo,
  useUserParameters,
  type ApolloPerson,
  type UserParameters
} from '../../../../../hooks';
import type { FormField, FormValues, SanityForm } from '../../../types';
import { evaluateConditionalLogic, evaluateFieldEnrich } from '../../../utils';

export const useProgressiveFormFields = (props: { form: SanityForm; eventId: string }) => {
  const { form, eventId } = props;
  const { fields: initialFields = [] } = form;

  const [currentFieldIndex, setCurrentFieldIndex] = useState(0);
  const [fields, setFields] = useState<FormField[]>(initialFields);

  const { query } = useRouter();

  const { apolloData, fetchApolloData } = useApollo();
  const { userParameters, fetchUserParameters } = useUserParameters();

  const nonHiddenFields = useMemo(
    () => fields.filter((field) => field.type !== 'hidden'),
    [fields]
  );

  const currentField = useMemo(
    () => nonHiddenFields[currentFieldIndex],
    [currentFieldIndex, nonHiddenFields]
  );

  const isCurrentFieldFirstField = currentFieldIndex === 0;

  const evaluateAndEnrichFormFields = useCallback(
    async (values: FormValues) => {
      const formValues = { ...values };

      let newApolloData: ApolloPerson | undefined = apolloData;
      let newUserParameters: UserParameters | undefined = userParameters;

      const emailField = initialFields.find((field) => field.type === 'email');
      const emailFieldValue = emailField ? formValues[emailField.name] : '';

      if (!userParameters) {
        newUserParameters = await fetchUserParameters();
      }

      if (emailField && emailFieldValue && apolloData?.email !== emailFieldValue) {
        newApolloData = await fetchApolloData({ email: emailFieldValue });
      }

      const newFields = initialFields.map((field) => {
        const { enrichValue, isEligibleForEnrichment } = evaluateFieldEnrich({
          field,
          apolloData: newApolloData,
          userParameters: newUserParameters,
          urlQuery: query,
          eventId
        });

        if (isEligibleForEnrichment && enrichValue !== formValues[field.name]) {
          formValues[field.name] = enrichValue;
        }

        if (isEligibleForEnrichment) {
          return { ...field, type: 'hidden' };
        }

        if (field.type !== 'hidden') {
          const { isFieldVisible } = evaluateConditionalLogic(field, formValues);

          return { ...field, type: isFieldVisible ? field.type : 'hidden' };
        }

        return field;
      });

      const newNonHiddenFields = newFields.filter((field) => field.type !== 'hidden');

      setFields(newFields);

      return {
        formValues,
        isCurrentFieldLastField: currentFieldIndex === newNonHiddenFields.length - 1
      };
    },
    [
      currentFieldIndex,
      apolloData,
      userParameters,
      initialFields,
      form,
      query,
      eventId,
      fetchUserParameters,
      fetchApolloData
    ]
  );

  const goToNextField = useCallback(() => {
    setCurrentFieldIndex((prev) => prev + 1);
  }, []);

  const goToPrevField = useCallback(() => {
    setCurrentFieldIndex((prev) => prev - 1);
  }, []);

  return {
    currentField,
    currentFieldIndex,

    isCurrentFieldFirstField,

    nonHiddenFields,

    goToPrevField,
    goToNextField,

    evaluateAndEnrichFormFields
  };
};
