import React, { useCallback, useState, type FC } from 'react';
import toast from 'react-hot-toast';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { AnimatePresence, motion } from 'framer-motion';
import type { SanityProgressiveFormByIdQueryResult } from 'sanity.types';
import { Box, Loader, ToastBody } from '@superside/ui';
import type { FormValues, SanityForm } from '../../types';
import { FormConsentFieldName } from '../../constants';
import { ProgressBar, PrevNextButtons, ProgressiveFormField } from './components';
import { useProgressiveForm } from './hooks';

const MotionBox = motion(Box);

export type ProgressiveFormProps = {
  form: SanityForm | NonNullable<SanityProgressiveFormByIdQueryResult>;
  initialValues?: FormValues;
  onFormSuccess?: () => void;
  onFormError?: () => void;
};

export const ProgressiveForm: FC<ProgressiveFormProps> = (props) => {
  const { form, initialValues, onFormError, onFormSuccess } = props;

  const { consent } = form;

  const handleFormError = useCallback(() => {
    toast.custom(
      (t) => (
        <ToastBody
          toastId={t.id}
          config={{
            text:
              form.toastErrorMessage ||
              "Oh no, something went wrong! Somewhere in the process an error occurred and your request couldn't be processed. Please try again",
            type: 'error',
            countDownDuration: 10
          }}
        />
      ),
      {
        duration: 10000
      }
    );
    onFormError?.();
  }, [form.toastErrorMessage, onFormError]);

  const {
    isPreparing,
    formProgress,
    formInitialValues,

    currentField,
    currentFieldIndex,
    isCurrentFieldLastField,

    handlePrev,
    handleNext
  } = useProgressiveForm({
    form,
    initialValues,
    onFormError: handleFormError,
    onFormSuccess
  });

  const [isLoading, setIsLoading] = useState(false);
  const setIsLoadingWithDelay = useCallback(
    (submitting: boolean, error?: boolean) => {
      if (submitting || error || !isCurrentFieldLastField) {
        setIsLoading(submitting);
      } else {
        // only able to submit every 10 seconds
        const timeoutId = setTimeout(() => setIsLoading(submitting), 10000);

        return () => clearTimeout(timeoutId);
      }
    },
    [isCurrentFieldLastField]
  );

  if (isPreparing) {
    return <Loader fullScreen />;
  }

  return (
    <FinalForm
      initialValues={{ ...formInitialValues, [FormConsentFieldName]: false }}
      onSubmit={handleNext}
      render={(formProps) => {
        const { handleSubmit, submitting, valid, touched, error } = formProps;

        setIsLoadingWithDelay(submitting, error);

        return (
          <>
            <form
              data-testid='progressive-form'
              className='relative flex h-full w-full flex-[0_0_auto] flex-col justify-center gap-4'
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Box className='relative justify-end'>
                <Box className='flex-row items-center justify-between gap-2'>
                  <AnimatePresence mode='wait'>
                    <MotionBox
                      key={currentFieldIndex}
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: 10, opacity: 0 }}
                      className='flex-1 items-end'
                    >
                      <ProgressiveFormField
                        field={currentField}
                        disabled={isLoading}
                        withConsent={consent?.enabled && consent?.field === currentField.name}
                      />
                    </MotionBox>
                  </AnimatePresence>

                  <PrevNextButtons
                    withBack={currentFieldIndex !== 0}
                    isLoading={isLoading}
                    onNext={handleSubmit}
                    onPrev={handlePrev}
                  />
                </Box>
              </Box>

              <ProgressBar
                progress={formProgress}
                isError={!valid && touched?.[currentField.name]}
              />
            </form>

            <FormSpy
              subscription={{
                active: true,
                modified: true,
                valid: true,
                validating: true,
                values: true
              }}
              onChange={(formState) => {
                // if the current field is grid-select and not multiple, and the field has been modified and valid, we submit the form
                if (
                  formState.modified?.[currentField.name] &&
                  formState.active === currentField.name &&
                  !formState.validating &&
                  formState.valid &&
                  formState.values[currentField.name] &&
                  currentField.type === 'grid-select' &&
                  !currentField?.multiple
                ) {
                  handleSubmit();
                }
              }}
            />
          </>
        );
      }}
    />
  );
};
