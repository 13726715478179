import React, { useCallback, type FC } from 'react';
import toast from 'react-hot-toast';
import { Form as FinalForm } from 'react-final-form';
import type { SanityFormBlock } from 'sanity.types';
import { cva } from 'class-variance-authority';
import { Box, Button, cn, ToastBody } from '@superside/ui';
import type { FormValues, SanityForm } from '../../types';
import { useFullForm } from './hooks';
import { FullFormField } from './components';

const formCva = cva('grid', {
  variants: {
    columns: {
      1: 'md:grid-cols-1',
      2: 'md:grid-cols-2'
    }
  }
});

export type FullFormProps = {
  form: SanityForm;
  initialValues?: FormValues;
  style?: SanityFormBlock['style'];
  submitLabel?: string;
  onFormSuccess?: () => void;
  onFormError?: () => void;
};

export const FullForm: FC<FullFormProps> = (props) => {
  const { form, initialValues, style, submitLabel = 'Submit', onFormError, onFormSuccess } = props;

  const { columns = 2, fieldVariant } = style || {};

  const handleFormError = useCallback(() => {
    toast.custom(
      (t) => (
        <ToastBody
          toastId={t.id}
          config={{
            text:
              form.toastErrorMessage ||
              "Oh no, something went wrong! Somewhere in the process an error occurred and your request couldn't be processed. Please try again",
            type: 'error',
            countDownDuration: 10
          }}
        />
      ),
      {
        duration: 10000
      }
    );
    onFormError?.();
  }, [form.toastErrorMessage, onFormError]);

  const { formInitialValues, handleSubmit } = useFullForm({
    form,
    initialValues,
    onFormError: handleFormError,
    onFormSuccess
  });

  const { fields } = form;

  const nonHiddenFields = fields.filter((field) => field.type !== 'hidden');

  return (
    <FinalForm
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      render={(formProps) => {
        const { handleSubmit: handleFormSubmit, submitting } = formProps;

        return (
          <form
            data-testid='full-form'
            className='relative flex w-full flex-[0_0_auto] flex-col justify-center gap-2'
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit();
            }}
          >
            <Box className='gap-2'>
              <Box className={cn('gap-2 gap-x-6', formCva({ columns }))}>
                {nonHiddenFields.map((field) => (
                  <FullFormField
                    key={field._key}
                    field={field}
                    disabled={submitting}
                    variant={fieldVariant}
                  />
                ))}
              </Box>

              <Button
                type='submit'
                loading={submitting}
                className={cn(columns === 1 ? 'w-full' : 'w-full md:w-fit md:self-end', 'min-w-36')}
              >
                {submitLabel}
              </Button>
            </Box>
          </form>
        );
      }}
    />
  );
};
