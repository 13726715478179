/* eslint-disable camelcase */
import { useCallback, useMemo } from 'react';
import { useRouter } from 'adapter/next/router';
import {
  baseTrackingFieldsGenerator,
  FORM_TRACKING_PROVIDERS,
  TRACKING_EVENT_NAMES,
  useAnalytics
} from '@superside/analytics';
import { prepareHubspotFormData } from '@superside/lego/src/utils/form';
import { hubSpotApiPoint, isDev, portalId } from '@superside/lego/src/blocks/BookCall/utils';
import { useChiliPiper } from '../../../hooks';
import type { FormValues, SanityForm } from '../types';
import { getLeadQualificationScore } from '../utils';
import { FormConsentFieldName, FormLeadScoreFieldName } from '../constants';
import { useFormSubmissionSuccess } from './useFormSubmissionSuccess';

const trackingProvider = FORM_TRACKING_PROVIDERS.INTERNAL_PROGRESSIVE;

type UseFormSubmissionProps = {
  form: SanityForm;
  eventId: string;
  onFormSuccess: (() => void) | undefined;
  onFormError: (() => void) | undefined;
};

export const useFormSubmission = (props: UseFormSubmissionProps) => {
  const { form, eventId, onFormError, onFormSuccess } = props;

  const { hubspotId, scheduler, trackingEvents, fields, trackingPayload } = form || {};

  const { enableScheduler, router: schedulerRouter } = scheduler || {};
  const {
    form: trackingForm,
    form_type: trackingFormType,
    item: trackingItem
  } = trackingEvents || {};

  const { track } = useAnalytics();
  const { asPath } = useRouter();

  const { submitChiliPiperFormAndScheduleCall, submitChiliPiperForm } = useChiliPiper({
    enabled: true,
    eventId
  });

  const baseTrackingFields = useMemo(
    () =>
      baseTrackingFieldsGenerator(
        eventId,
        hubspotId,
        {
          form: trackingForm?.form,
          form_type: trackingFormType?.form_type,
          item: trackingItem?.item
        },
        asPath
      ),
    [eventId, hubspotId, trackingForm, trackingFormType, trackingItem, asPath]
  );

  const { handleFormSuccess } = useFormSubmissionSuccess({ form });

  const prepareFormValues = useCallback(
    async (formValues: FormValues) => {
      const finalFormValues = Object.fromEntries(
        Object.entries(formValues).filter(([key, value]) => key !== FormConsentFieldName && value)
      );

      const leadScore = await getLeadQualificationScore({
        formValues,
        form
      });

      return {
        ...finalFormValues,
        ...(leadScore?.score ? { [FormLeadScoreFieldName]: leadScore.score } : {})
      };
    },
    [form]
  );

  const prepareTrackingPayload = useCallback(
    (formValues: FormValues) => {
      const formTrackingPayload = trackingPayload?.reduce((acc, payloadItem) => {
        if (payloadItem._type === 'payloadItem') {
          return {
            ...acc,
            [payloadItem.key]: formValues[payloadItem.value]
          };
        }

        if (payloadItem._type === 'formFieldPayloadItem') {
          return {
            ...acc,
            [payloadItem.key || payloadItem.fieldName]: formValues[payloadItem.fieldName]
          };
        }

        return acc;
      }, {});

      return {
        ...baseTrackingFields,
        ...formTrackingPayload,
        provider: trackingProvider
      };
    },
    [baseTrackingFields, trackingPayload]
  );

  const handleSchedulerSubmit = useCallback(
    async (
      formValues: FormValues,
      preparedTrackingPayload: Record<
        string,
        string | undefined | Record<string, string | undefined>
      >
    ) => {
      const isInternalLead = fields.some(
        (field) => field.type === 'email' && formValues[field.name]?.includes('@superside.com')
      );

      const chiliPiperRouter =
        isDev || isInternalLead ? 'test_router' : schedulerRouter?.routerName || 'inbound-router';

      if (enableScheduler) {
        await submitChiliPiperFormAndScheduleCall({
          lead: formValues,
          routerName: chiliPiperRouter,
          trackingPayload: preparedTrackingPayload
        });
      } else {
        await submitChiliPiperForm({
          lead: formValues,
          routerName: chiliPiperRouter,
          trackingPayload: preparedTrackingPayload
        });
      }
    },
    [
      fields,
      schedulerRouter?.routerName,
      enableScheduler,
      submitChiliPiperFormAndScheduleCall,
      submitChiliPiperForm
    ]
  );

  const handleHubspotFormSubmit = useCallback(
    (values: FormValues) => {
      const hubspotFields = [...Object.entries({ ...values })]
        .map(([name, value]) => {
          if (Array.isArray(value)) {
            return value.map((val) => {
              return {
                name,
                value: val
              };
            });
          }

          return {
            name,
            value
          };
        })
        .flat();

      const data = prepareHubspotFormData(hubspotFields);

      return fetch(`${hubSpotApiPoint}/${portalId}/${hubspotId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
    },
    [hubspotId]
  );

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      const preparedFormValues = await prepareFormValues(formValues);
      const preparedTrackingPayload = prepareTrackingPayload(formValues);

      return Promise.all([
        handleHubspotFormSubmit(preparedFormValues).then(() => {
          // we need to call the FORM_SUBMISSION event after the hubspot form submission
          track(TRACKING_EVENT_NAMES.FORM_SUBMISSION, {
            ...preparedTrackingPayload
          });
        }),
        handleSchedulerSubmit(preparedFormValues, preparedTrackingPayload)
      ])
        .then(async () => {
          onFormSuccess?.();
          handleFormSuccess(formValues);
        })
        .catch((err) => {
          onFormError?.();
          track(TRACKING_EVENT_NAMES.FORM_SUBMISSION_FAILURE, {
            ...preparedTrackingPayload,
            error: err?.message
          });
        });
    },
    [
      track,
      onFormError,
      onFormSuccess,
      prepareFormValues,
      prepareTrackingPayload,
      handleFormSuccess,
      handleSchedulerSubmit,
      handleHubspotFormSubmit
    ]
  );

  return { handleSubmit };
};
